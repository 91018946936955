import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../../components/atoms/seo'
import OfficialContactLayout from '../../components/organisms/officialContactLayout'
import OfficialLowDownload02 from '../../components/organisms/officialDownload02'
import OfficialLowHeader from '../../components/organisms/officialLowHeader'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.main`
  font-size: 16px;
  height: 100%;
  .p-head-inner {
    .p-nav-area {
      display: none;
    }
  }
  ${BreakPoints.xLarge} {
    .p-head-inner {
      .p-nav-area {
        display: flex;
      }
    }
  }
`

/**
 * LP用資料ダウンロード完了
 * @constructor
 */
const Lp01BThanksPage = () => (
  <OfficialContactLayout>
    <SEO title="資料ダウンロード" />
    <Wrapper className="thanks">
      <OfficialLowHeader
        label="資料ダウンロード"
        description="スライド資料で簡潔にキンクラをご紹介します"
      />
      <OfficialLowDownload02 />
    </Wrapper>
  </OfficialContactLayout>
)

export default Lp01BThanksPage

export const pageQuery = graphql`
  query Lp01BThanksPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
